import {createStore} from 'vuex'

export default createStore({
    state: {
        discount: 0,
        ticket: {}
    },
    getters: {
        getTicket(state) {
            return state.ticket;
        },
        getDiscount(state) {
            return state.discount;
        }
    },
    mutations: {
        setTicket(state, ticket) {
            state.ticket = ticket;
        },
        setDiscount(state, discount) {
            state.discount = discount;
        }
    },
    actions: {},
    modules: {}
})
